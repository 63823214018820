






























































































import CSelectEmployee from "@/components/shared/select-employee/CSelectEmployee.vue";
import { PatchFormField, State } from "@/store/user.store";
import { checkPasswordStrength, emailValidator } from "@/validator/globalvalidator";
import { ISelectOption } from "@interface/common.interface";
import { ContactData } from "@interface/contact.interface";
import { FormModel } from "ant-design-vue";
import Vue from "vue";
import { mapMutations, mapState } from "vuex";

export default Vue.extend({
  name: "FormGeneral",
  components: {
    CSelectEmployee,
  },
  props: {
    userId: {
      required: false,
      type: String,
      default: "",
    }
  },
  data() {
    return {
      formModel: null as FormModel | null,
      formRules: {
        employeeName: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        username: [
          { required: true, message: this.$t("lbl_validation_required_error") },
        ],
        password: [
          { required: true, message: this.$t("lbl_validation_required_error") },
          {validator: checkPasswordStrength}
        ],
        confirmPassword: [
          { required: true, message: this.$t("lbl_validation_required_error") },
          {
            validator: this.matchPassword,
          },
        ],
        email: [
          { required: true, message: this.$t("lbl_validation_required_error") },
          { validator: emailValidator },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      store: (st: any) => st.userStore as State,
    }),
  },
  mounted(): void {
    this.formModel = this.$refs.formModel as FormModel;
  },
  methods: {
    ...mapMutations({
      setForm: "userStore/setForm",
    }),
    onChangeEmployee(opt?: ISelectOption<ContactData>): void {
      const form: PatchFormField = {
        employeeId: opt?.meta.id,
      };
      this.setForm(form);
    },
    matchPassword(
      _rule,
      value: string | undefined,
      callback: (str?: string) => void
    ): void {
      if (this.store.form.password !== value) {
        callback(this.$t("lbl_password_doesnt_match").toString());
      } else {
        callback();
      }
    },
    onChangePassword(): void {
      const form: PatchFormField = {
        confirmPassword: "",
      };
      this.setForm(form);
    }
  },
});
