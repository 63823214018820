





























import { FormAdditionalPrivilege, FormGeneral, FormRole } from "@/components/user";
import { useUser } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Mode } from "@/models/enums/global.enum";
import { UpdateUserDto } from "@/models/interface-v2/user.interface";
import { FormValue, State as UserStore } from "@/store/user.store";
import { FormModel } from "ant-design-vue";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
    name: "FormPage",
    components: {
      FormGeneral,
      FormRole,
      FormAdditionalPrivilege,
    },
    mixins: [MNotificationVue],
    props: {
      id: {
        required: false,
        type: String,
        default: "",
      },
    },
    data() {
      return {
        loading: false,
      };
    },
    computed: {
      ...mapState({
        store: (st: any) => st.userStore as UserStore
      }),
      isEdit(): boolean {
        return !!this.id && this.$route.meta?.mode === Mode.EDIT;
      }
    },
    methods: {
      handleBack(): void {
        this.$router.push({
          name: "user-management.users",
          query: this.$route.query,
        });
      },
      handleSubmit(): void {
        this.validateForm();
      },
      async validateForm(): Promise<void> {
        const modelGeneral: FormModel = (this.$refs.formGeneral as any).formModel;
        const modelRole: FormModel = (this.$refs.formRole as any).formModel;
        try {
          await modelGeneral.validate();
          await modelRole.validate();

          if (this.isEdit) {
            this.handleUpdate(this.id, this.store.form);
          } else {
            this.handleCreate(this.store.form);
          }
        } catch (error) {
          this.showNotifWarning("notif_validation_error");
        }
      },
      async handleUpdate(userId: string, form: FormValue): Promise<void> {
        const { update, mapFormToUpdateDto } = useUser();
        try {
          this.loading = true;
          const req: UpdateUserDto = mapFormToUpdateDto(form);
          await update(userId, req);
          this.showNotifSuccess("notif_update_success");
          this.handleBack();
        } catch (error) {
          this.showNotifError("notif_update_fail");
        } finally {
          this.loading = false;
        }
      },
      async handleCreate(form: FormValue): Promise<void> {
        const { create, mapFormToCreateDto } = useUser();
        try {
          this.loading = true;
          const req = mapFormToCreateDto(form);
          await create(req);
          this.showNotifSuccess("notif_create_success");
          this.handleBack();
        } catch (error) {
          this.showNotifError("notif_create_fail");
        } finally {
          this.loading = false;
        }
      }
    }
});
