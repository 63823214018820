var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_role")) + " ")]),
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: {
            model: _vm.store.form,
            rules: _vm.formRules,
            "label-col": { span: 4 },
            "wrapper-col": { span: 6 },
            "label-align": "left"
          }
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: _vm.$t("lbl_role"), prop: "roleNames" } },
            [
              _c("SelectRole", {
                model: {
                  value: _vm.store.form.roleNames,
                  callback: function($$v) {
                    _vm.$set(_vm.store.form, "roleNames", $$v)
                  },
                  expression: "store.form.roleNames"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }