










































































import CSelectMenu from "@/components/shared/select-menu/CSelectMenu.vue";
import { AdditionalPrivilegeRow, State } from "@/store/user.store";
import { ISelectOption } from "@interface/common.interface";
import { ListMenuDto } from "@interface/menu.interface";
import Vue from "vue";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  name: "FormAdditionalPrivilege",
  components: {
    CSelectMenu,
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("lbl_menu"),
          dataIndex: "menuName",
          // width: "200px",
          scopedSlots: { customRender: "menu" },
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "menuDesc",
          // width: "200px",
        },
        {
          title: this.$t("lbl_create"),
          dataIndex: "create",
          width: "100px",
          align: "right",
          scopedSlots: { customRender: "create" },
        },
        {
          title: this.$t("lbl_read"),
          dataIndex: "read",
          width: "100px",
          align: "right",
          scopedSlots: { customRender: "read" },
        },
        {
          title: this.$t("lbl_update"),
          dataIndex: "update",
          width: "100px",
          align: "right",
          scopedSlots: { customRender: "update" },
        },
        {
          title: this.$t("lbl_delete"),
          dataIndex: "delete",
          width: "100px",
          align: "right",
          scopedSlots: { customRender: "delete" },
        },
      ],
      selectedRowKeys: [] as Array<string>,
    };
  },
  computed: {
    ...mapState({
      store: (st: any) => st.userStore as State,
    }),
  },
  methods: {
    ...mapActions({
      addRow: "userStore/addAdditionalPrivilege",
      deleteAdditionalPrivileges: "userStore/deleteAdditionalPrivileges",
    }),
    onSelectChange(keys: Array<string>): void {
      this.selectedRowKeys = keys;
    },
    onChangeMenu(row: AdditionalPrivilegeRow, ev?: ISelectOption<ListMenuDto>): void {
      row.menuName = ev?.label ?? "";
      row.idMenu = ev?.meta.id ?? "";
      row.menuDesc = ev?.meta.description ?? "";

      // set default checked value
      row.isCreate = !!ev?.meta.create ?? false;
      row.isRead = !!ev?.meta.read ?? false;
      row.isUpdate = !!ev?.meta.update ?? false;
      row.isDelete = !!ev?.meta.delete ?? false;

      // set secure id on each actions
      row.create = ev?.meta.create ?? "";
      row.read = ev?.meta.read ?? "";
      row.update = ev?.meta.update ?? "";
      row.delete = ev?.meta.delete ?? "";
    },
    handleDeleteRow(): void {
      this.deleteAdditionalPrivileges(this.selectedRowKeys);
    },
  },
});
