















import CSelect from "@/components/custom/select/CSelect.vue";
import { debounceProcess } from "@/helpers/debounce";
import { useMenu } from "@/hooks";
import { FIRST_PAGE, ONE } from "@/models/constant/global.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ISelectOption } from "@interface/common.interface";
import { ListMenuDto } from "@interface/menu.interface";
import Vue from "vue";

export default Vue.extend({
  name: "CSelectMenu",
  components: {
    CSelect,
  },
  props: {
    value: {
      required: true,
      default: "",
      type: String,
    },
  },
  data() {
    this.onSearch = debounceProcess(this.onSearch, 500);
    return {
      loading: false,
      options: [] as Array<ISelectOption<ListMenuDto>>,
      allLoaded: false,
      pagination: {
        page: FIRST_PAGE,
        search: "",
      },
    };
  },
  mounted() {
    this.fetchOptions(this.buildParams());
  },
  methods: {
    async fetchOptions(params: RequestQueryParamsModel): Promise<void> {
      const { findAll, toOptions } = useMenu();
      try {
        this.loading = true;
        const res = await findAll(params);
        this.allLoaded = res.currentPage + ONE === res.totalPages;
        const copy = [...this.options];
        this.options = [...copy, ...toOptions(res.data)];
      } finally {
        this.loading = false;
      }
    },
    findOptions(e?: string): ISelectOption<ListMenuDto> | undefined {
      return this.options.find((item) => item.value === e);
    },
    onChange(e?: string): void {
      this.$emit("input", e);
      this.$emit("change", e);
      this.$emit("update:meta", this.findOptions(e));
    },
    buildParams(): RequestQueryParamsModel {
      return {
        page: this.pagination.page - ONE,
        search: this.pagination.search,
        sorts: "name:asc",
      };
    },
    onScrollEnd(): void {
      if (this.loading || this.allLoaded) return;
      this.pagination.page += ONE;
      this.fetchOptions(this.buildParams());
    },
    resetState(): void {
        this.pagination.page = FIRST_PAGE;
        this.allLoaded = false;
        this.options = [];
    },
    onSearch(e?: string): void {
        const { findByQuery } = useMenu();
        this.resetState();
        const [query] = findByQuery({name: e});
        this.pagination.search = query;

        this.fetchOptions(this.buildParams());
    },
  },
});
