
















import CSelect from "@/components/custom/select/CSelect.vue";
import { debounceProcess } from "@helper/debounce";
import { useContactData } from "@/hooks";
import { FIRST_PAGE, ONE } from "@constant/global.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ISelectOption } from "@interface/common.interface";
import { ContactData } from "@interface/contact.interface";
import { QueryBuilder } from "@/builder";
import Vue from "vue";

export default Vue.extend({
  name: "CSelectEmployee",
  components: {
    CSelect,
  },
  props: {
    value: {
      type: String,
      required: true,
      default: "",
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    this.onSearch = debounceProcess(this.onSearch, 500);
    return {
      loading: false,
      options: [] as Array<ISelectOption<ContactData>>,
      allLoaded: false,
      pagination: {
        page: FIRST_PAGE,
        search: "",
      },
    };
  },
  mounted(): void {
    this.fetchOptions(this.buildParams());
  },
  methods: {
    async fetchOptions(params: RequestQueryParamsModel): Promise<void> {
      const { findAllEmployee, toEmployeeOptions } = useContactData();
      try {
        this.loading = true;
        const res = await findAllEmployee(params);
        this.allLoaded = res.currentPage + ONE === res.totalPages;

        const copy = [...this.options];
        this.options = [...copy, ...toEmployeeOptions(res.data)];
      } finally {
        this.loading = false;
      }
    },
    onChange(e?: string): void {
      this.$emit("input", e);
      this.$emit("change", e);
      this.$emit("update:meta", this.findOption(e));
    },
    findOption(e?: string): ISelectOption<ContactData> | undefined {
      return this.options.find((item) => item.value === e);
    },
    resetState(): void {
      this.options = [];
      this.pagination.page = FIRST_PAGE;
      this.allLoaded = false;
    },
    onSearch(e?: string): void {
      const { findByQuery } = useContactData();
      this.resetState();
      this.pagination.search = findByQuery({
        firstName: e,
        lastName: e,
      }).join(QueryBuilder.OR);
      this.fetchOptions(this.buildParams());
    },
    onScrollEnd(): void {
      if (this.loading || this.allLoaded) return;
      this.pagination.page += ONE;
      this.fetchOptions(this.buildParams());
    },
    buildParams(): RequestQueryParamsModel {
      const params: RequestQueryParamsModel = {
        page: this.pagination.page - ONE,
        search: this.pagination.search ?? "",
      };
      return params;
    },
  },
});
