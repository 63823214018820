var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c("legend", [
        _vm._v(" " + _vm._s(_vm.$t("lbl_additional_privilege")) + " ")
      ]),
      _c("a-table", {
        attrs: {
          "data-source": _vm.store.form.additionalPrivilege,
          columns: _vm.columns,
          pagination: false,
          scroll: { y: 500 },
          "row-selection": {
            selectedRowKeys: _vm.selectedRowKeys,
            onChange: _vm.onSelectChange
          }
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function() {
              return [
                _c(
                  "a-space",
                  [
                    _c(
                      "a-button",
                      {
                        attrs: { type: "primary", icon: "plus" },
                        on: { click: _vm.addRow }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                    ),
                    _c(
                      "a-button",
                      {
                        attrs: {
                          type: "danger",
                          icon: "delete",
                          disabled: !_vm.selectedRowKeys.length
                        },
                        on: { click: _vm.handleDeleteRow }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "menu",
            fn: function(text, row) {
              return [
                _c("CSelectMenu", {
                  staticClass: "w-100",
                  on: {
                    "update:meta": function(ev) {
                      return _vm.onChangeMenu(row, ev)
                    }
                  },
                  model: {
                    value: row.menuName,
                    callback: function($$v) {
                      _vm.$set(row, "menuName", $$v)
                    },
                    expression: "row.menuName"
                  }
                })
              ]
            }
          },
          {
            key: "read",
            fn: function(text, row) {
              return [
                _c("a-switch", {
                  attrs: { disabled: !row.read },
                  model: {
                    value: row.isRead,
                    callback: function($$v) {
                      _vm.$set(row, "isRead", $$v)
                    },
                    expression: "row.isRead"
                  }
                })
              ]
            }
          },
          {
            key: "create",
            fn: function(text, row) {
              return [
                _c("a-switch", {
                  attrs: { disabled: !row.create },
                  model: {
                    value: row.isCreate,
                    callback: function($$v) {
                      _vm.$set(row, "isCreate", $$v)
                    },
                    expression: "row.isCreate"
                  }
                })
              ]
            }
          },
          {
            key: "update",
            fn: function(text, row) {
              return [
                _c("a-switch", {
                  attrs: { disabled: !row.update },
                  model: {
                    value: row.isUpdate,
                    callback: function($$v) {
                      _vm.$set(row, "isUpdate", $$v)
                    },
                    expression: "row.isUpdate"
                  }
                })
              ]
            }
          },
          {
            key: "delete",
            fn: function(text, row) {
              return [
                _c("a-switch", {
                  attrs: { disabled: !row.delete },
                  model: {
                    value: row.isDelete,
                    callback: function($$v) {
                      _vm.$set(row, "isDelete", $$v)
                    },
                    expression: "row.isDelete"
                  }
                })
              ]
            }
          }
        ])
      }),
      _c("div", { staticClass: "mt-2 text-right" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("lbl_total_items", {
                total: _vm.store.form.additionalPrivilege.length
              })
            ) +
            " "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }