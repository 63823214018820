var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      attrs: {
        value: _vm.value,
        "allow-clear": _vm.allowClear,
        "show-search": _vm.showSearch,
        "filter-option": _vm.filterOption,
        loading: _vm.loading,
        disabled: _vm.disabled,
        "option-filter-prop": _vm.optionFilterProp,
        "dropdown-match-select-width": _vm.dropdownMatchSelectWidth,
        mode: _vm.mode,
        "label-in-value": _vm.labelInValue
      },
      on: {
        search: _vm.onSearch,
        change: _vm.onChange,
        popupScroll: _vm.onScroll,
        select: _vm.onSelect
      }
    },
    [
      _vm._t(
        "default",
        function() {
          return _vm._l(_vm.options, function(option) {
            return _c(
              "a-select-option",
              { key: option.key, attrs: { value: option.value } },
              [
                _vm._t(
                  "option",
                  function() {
                    return [
                      _c("a-tooltip", [
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v(" " + _vm._s(option.label) + " ")]
                        ),
                        _vm._v(" " + _vm._s(option.label) + " ")
                      ])
                    ]
                  },
                  { option: option }
                )
              ],
              2
            )
          })
        },
        { options: _vm.options }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }