


















import { QueryBuilder } from "@/builder";
import CSelect from "@/components/custom/select/CSelect.vue";
import { debounceProcess } from "@/helpers/debounce";
import { useRole } from "@/hooks";
import { FIRST_PAGE, ONE } from "@/models/constant/global.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ISelectOption } from "@interface/common.interface";
import { ListRoleDto } from "@interface/role.interface";
import Vue from "vue";

export default Vue.extend({
  name: "SelectRole",
  components: {
    CSelect,
  },
  props: {
    value: {
      type: Array as () => Array<string>,
      required: true,
      default: "",
    },
  },
  data() {
    this.onSearch = debounceProcess(this.onSearch, 500);
    return {
      loading: false,
      options: [] as Array<ISelectOption<ListRoleDto>>,
      pagination: {
        page: FIRST_PAGE,
        search: "",
      },
      allLoaded: false,
    };
  },
  mounted(): void {
    this.fetchOptions(this.buildParams());
  },
  methods: {
    onChange(e?: Array<string>): void {
      this.$emit("input", e);
      this.$emit("change", e);
    },
    onSearch(e?: string): void {
      const { findByQuery } = useRole();
      this.resetState();
      this.pagination.search = findByQuery({ name: e }).join(QueryBuilder.AND);

      this.fetchOptions(this.buildParams());
    },
    resetState(): void {
      this.options = [];
      this.pagination.page = FIRST_PAGE;
      this.allLoaded = false;
    },
    onScrollEnd(): void {
      if (this.loading || this.allLoaded) return;
      this.pagination.page += ONE;
      this.fetchOptions(this.buildParams());
    },
    async fetchOptions(params: RequestQueryParamsModel): Promise<void> {
      const { findAll, toOptions } = useRole();
      try {
        this.loading = true;
        const res = await findAll(params);
        this.allLoaded = res.currentPage + ONE === res.totalPages;
        const copy = [...this.options];
        this.options = [...copy, ...toOptions(res.data)];
      } finally {
        this.loading = false;
      }
    },
    buildParams(): RequestQueryParamsModel {
      return {
        page: this.pagination.page - ONE,
        search: this.pagination.search,
        sorts: "name:asc"
      };
    },
    onSelect(e: string): void {
      this.$emit("select", e);
    },
  },
});
