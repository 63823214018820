

























import { State } from "@/store/user.store";
import { RoleMenuDto } from "@interface/role.interface";
import { FormModel } from "ant-design-vue";
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import SelectRole from "./SelectRole.vue";

export default Vue.extend({
  name: "FormRole",
  components: {
    SelectRole,
  },
  props: {
    userId: {
      type: String,
      default: "",
      required: false,
    },
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("lbl_menu"),
          dataIndex: "name",
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          // width: "200px",
        },
        {
          title: this.$t("lbl_create"),
          dataIndex: "create",
          width: "100px",
          align: "right",
          scopedSlots: { customRender: "boolean" },
        },
        {
          title: this.$t("lbl_read"),
          dataIndex: "read",
          width: "100px",
          align: "right",
          scopedSlots: { customRender: "boolean" },
        },
        {
          title: this.$t("lbl_update"),
          dataIndex: "update",
          width: "100px",
          align: "right",
          scopedSlots: { customRender: "boolean" },
        },
        {
          title: this.$t("lbl_delete"),
          dataIndex: "delete",
          width: "100px",
          align: "right",
          scopedSlots: { customRender: "boolean" },
        },
      ],
      currentTab: "",
      dtRole: [] as Array<RoleMenuDto>,
      loading: false,
      formRules: {
        roleNames: [{required: true, message: this.$t("lbl_validation_required_error")}]
      },
      formModel: null as null | FormModel
    };
  },
  computed: {
    ...mapState({
      store: (st: any) => st.userStore as State,
    }),
  },
  mounted(): void {
    this.formModel = this.$refs.formModel as FormModel;
  },
  methods: {
    ...mapMutations({
      setForm: "userStore/setForm",
    }),
  },
});
