var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "fieldset",
    [
      _c("legend", [_vm._v(" " + _vm._s(_vm.$t("lbl_general")) + " ")]),
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: {
            model: _vm.store.form,
            rules: _vm.formRules,
            "label-col": { span: 8 },
            "wrapper-col": { span: 12 },
            "label-align": "left"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_employee"),
                        prop: "employeeName"
                      }
                    },
                    [
                      _c("CSelectEmployee", {
                        attrs: { name: "user-employee_name" },
                        on: { "update:meta": _vm.onChangeEmployee },
                        model: {
                          value: _vm.store.form.employeeName,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "employeeName", $$v)
                          },
                          expression: "store.form.employeeName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_username"), prop: "username" }
                    },
                    [
                      _c("a-input", {
                        attrs: { name: "user-username", "allow-clear": "" },
                        model: {
                          value: _vm.store.form.username,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "username", $$v)
                          },
                          expression: "store.form.username"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_email"), prop: "email" } },
                    [
                      _c("a-input", {
                        attrs: { name: "user-email", "allow-clear": "" },
                        model: {
                          value: _vm.store.form.email,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "email", $$v)
                          },
                          expression: "store.form.email"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_active"), prop: "active" } },
                    [
                      _c("a-switch", {
                        attrs: { name: "user-active" },
                        model: {
                          value: _vm.store.form.active,
                          callback: function($$v) {
                            _vm.$set(_vm.store.form, "active", $$v)
                          },
                          expression: "store.form.active"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              !_vm.userId
                ? _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_password"),
                            prop: "password",
                            "label-align": "right"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "extra",
                                fn: function() {
                                  return [
                                    _c("ul", [
                                      _c("li", [
                                        _vm._v(
                                          _vm._s(_vm.$t("lbl_password_rule_1"))
                                        )
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          _vm._s(_vm.$t("lbl_password_rule_2"))
                                        )
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          _vm._s(_vm.$t("lbl_password_rule_3"))
                                        )
                                      ]),
                                      _c("li", [
                                        _vm._v(
                                          _vm._s(_vm.$t("lbl_password_rule_4"))
                                        )
                                      ])
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            false,
                            1386747081
                          )
                        },
                        [
                          _c("a-input-password", {
                            attrs: { name: "user-password" },
                            on: { change: _vm.onChangePassword },
                            model: {
                              value: _vm.store.form.password,
                              callback: function($$v) {
                                _vm.$set(_vm.store.form, "password", $$v)
                              },
                              expression: "store.form.password"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_confirm_password"),
                            prop: "confirmPassword",
                            "label-align": "right"
                          }
                        },
                        [
                          _c("a-input-password", {
                            attrs: { name: "user-confirm_password" },
                            model: {
                              value: _vm.store.form.confirmPassword,
                              callback: function($$v) {
                                _vm.$set(_vm.store.form, "confirmPassword", $$v)
                              },
                              expression: "store.form.confirmPassword"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }