










































import { ISelectOption } from "@interface/common.interface";
import Vue from "vue";

export default Vue.extend({
  name: "CSelect",
  props: {
    mode: {
      required: false,
      type: String,
      default: "default",
    },
    value: {
      required: true,
    },
    allowClear: {
      required: false,
      type: Boolean,
      default: false,
    },
    showSearch: {
      required: false,
      type: Boolean,
      default: false,
    },
    loading: {
      required: false,
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    dropdownMatchSelectWidth: {
      required: false,
      type: Boolean,
      default: true,
    },
    labelInValue: {
      required: false,
      type: Boolean,
      default: false,
    },
    filterOption: {
      required: false,
    },
    optionFilterProp: {
      required: false,
    },
    options: {
      type: Array as () => Array<ISelectOption>,
      required: true,
    },
  },
  methods: {
    onSearch(e?: string): void {
      this.$emit("search", e);
    },
    onChange(e?: string): void {
      this.$emit("input", e);
      this.$emit("change", e);
    },
    onSelect(e): void {
      this.$emit("select", e);
    },
    onDeselect(e): void {
      this.$emit("deselect", e);
    },
    onScroll(e): void {
      this.$emit("popupScroll", e);
      const treshold = 30;
      const target = e.target;
      if (
        target.scrollTop + target.offsetHeight >=
        target.scrollHeight - treshold
      ) {
        this.emitReachBottom();
      }
    },
    emitReachBottom(): void {
      this.$emit("scrollEnd");
    },
  },
});
