var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_create_user") } },
    [
      _c("FormGeneral", { ref: "formGeneral", attrs: { "user-id": _vm.id } }),
      _c("FormRole", { ref: "formRole", attrs: { "user-id": _vm.id } }),
      _c("FormAdditionalPrivilege"),
      _c(
        "div",
        { staticClass: "mt-5 text-right" },
        [
          _c(
            "a-space",
            [
              _c("a-button", { on: { click: _vm.handleBack } }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
              ]),
              _c(
                "a-button",
                {
                  attrs: {
                    icon: "save",
                    type: "primary",
                    loading: _vm.loading
                  },
                  on: { click: _vm.handleSubmit }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }